import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

function App() {

  const [music, setMusic] = useState([]);
  const [activeSong, setActiveSong] = useState(null);
  const audioRefs = useRef({});

  useEffect(() => {
    const fetchMusic = async () => {
      try {
        const response = await axios.get(
          "https://agent.zap.marketing/music",
        );
        const musicData = response.data;
        setMusic(musicData);

        // Initialize audio references for each music item
        musicData.forEach((song) => {
          const url = `https://i.zap.marketing/music/${song}.mp3`;
          audioRefs.current[song] = new Audio(url);
        });
      } catch (error) {
        console.error("Failed to fetch music:", error);
      }
    };
    fetchMusic();
  }, []);

  const openWhatsApp = (event, name) => {
    event.preventDefault();
    const text = `${name}: Hola, este es un texto de prueba de voces para ZAP.\n¡Promueve tu negocio con nosotros!`
    window.open(
      `https://api.whatsapp.com/send?phone=+14156102615&text=${encodeURIComponent(
        text,
      )}`,
    );
    window.open("", "_self").close();
  };

  const toggleAudio = (name) => {
    const audio = audioRefs.current[name];

    if (activeSong === name) {
      audio.pause();
      audio.currentTime = 0;
      setActiveSong(null);
    } else {
      Object.keys(audioRefs.current).forEach((k) => {
        const otherAudio = audioRefs.current[k];
        otherAudio.pause();
        otherAudio.currentTime = 0;
      });
      audio.play();
      setActiveSong(name);
    }
  };

  return (
    <div className="bg-neutro-1 px-2.4 py-[30px] min-h-screen h-full relative flex flex-col">
      <div
        style={{ width: "100%" }}
        className="mx-auto px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8"
      >
        <img width={100} src="/logo.png" alt="Logo" />
        <div className="py-10 flex flex-wrap -m-4">
          {music.map((song) => {
            const isActive = activeSong === song;
            return (
              <div
                className="group relative p-4 w-1/2 sm:w-1/2 lg:w-1/4"
                key={song}
                onClick={() => toggleAudio(song)}
              >
                <div className="aspect-content">
                  <span
                    style={{ position: "absolute", right: 25, top: 25 }}
                    className="inline-flex items-center rounded-full bg-green-50 px-1.5 py-0.5 text-medium font-large text-blue-700 ring-1 ring-inset ring-blue-600/20"
                  >
                    Pro
                  </span>
                  <div className="group relative">
                    <img
                      src={`/music/${song}.jpeg`}
                      className="object-cover object-center h-full w-full border border-gray-400"
                    />
                    <img
                      src={isActive ? "/stop.png" : "/play.png"}
                      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                      style={{ width: "50%", opacity: "0.8" }}
                    />
                  </div>
                </div>
                <div className="mt-4 flex justify-between text-gray-500">
                  <div className="py-2">music: {song}</div>
                  {/* <button 
                    className="inline-flex items-center gap-0.5 justify-center overflow-hidden text-sm font-medium transition rounded-full bg-zinc-900 py-1 px-3 text-white hover:bg-zinc-700"
                    onClick={(e) => openWhatsApp(e, song)}
                  >
                    Select
                    <svg
                      viewBox="0 0 20 20"
                      fill="none"
                      aria-hidden="true"
                      className="ml-1 h-5 w-5"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m11.5 6.5 3 3.5m0 0-3 3.5m3-3.5h-9"
                      ></path>
                    </svg>
                  </button> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default App;
